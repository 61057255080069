.user-Accessibility-Header {
    margin-bottom: 0px Im !important;
  }

  .form-content-padding-LeftRight{
    padding-left: 52px;
    padding-right: 52px;
  }

  .required-asterisk{
    color: red;
  }

  .react-datetime-picker__wrapper{
    border:none !important
  }