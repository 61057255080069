.login-form {
    min-width: 500px;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem
  }


.login-container {
  display:flex; 
  width:100%;
  height:100vh;
  justify-content: center;
  align-items: center;
}

.login-form-2 {
  width:500px;
  border:1px solid grey;
  padding:1rem;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 10px lightblue;
}

.clserrorLabel{ 
  color: red;
}

