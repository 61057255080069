.bgcolor {
    background-color: cadetblue;
}

.sb-width {
    width:inherit;
    position:fixed;
}

.bglight {
    background-color: rgba(0, 0, 0, 0.10);
}

.leftnav{
    width: 165px !important;
    
}

.rightdiv{
    width: -webkit-calc(100% - 168px);
    width:    -moz-calc(100% - 168px);
    width:         calc(100% - 168px);
}
