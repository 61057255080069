.container{
    max-width: 100% !important;
}
.tableStyle{
    font-size: 13px !important;
}
.table>:not(caption)>*>*{
    padding: .3rem .2rem !important
}


table th:nth-child(6) {
    width: 300px !important;
    inline-size: 150px;
    overflow-wrap: break-word;
}
table td:nth-child(6) {
    width: 300px !important;
    inline-size: 150px;
    overflow-wrap: break-word;
}